import req from "../methods/req"
import method from '../methods/method'

//指标分类列表
export const getQuotaCateList = function ({data}){
    return req({url:'/manage/qms.quota/getQuotaCateList',data})
}
//新增或编辑指标分类
export const saveQuotaCate = function (data){
    return req({url:'/manage/qms.quota/saveQuotaCate',data})
}
//编辑指标分类状态
export const changeCateStatus = function (data){
    return req({url:'/manage/qms.quota/changeCateStatus',data})
}
//删除指标分类
export const delQuotaCate = function (data){
    return req({url:'/manage/qms.quota/delQuotaCate',data})
}
//指标列表
export const getQuotaInfoList = function ({_this,data}){
    return method.get_list({_this,url:'/manage/qms.quota/getQuotaInfoList',data})
}
//读取指标详情
export const getQuotaDetail = function (data){
    return req({url:'/manage/qms.quota/getQuotaDetail',data})
}
//新增或编辑指标
export const saveQuotaInfo = function (data){
    return req({url:'/manage/qms.quota/saveQuotaInfo',data})
}
//编辑指标状态
export const changeQuotaStatus = function (data){
    return req({url:'/manage/qms.quota/changeQuotaStatus',data})
}
//删除指标
export const delQuotaInfo = function (data){
    return req({url:'/manage/qms.quota/delQuotaInfo',data})
}
//获取指标计算符号
export const getSymbolString = function (data){
    return req({url:'/manage/qms.quota/getSymbolString',data})
}


//产品类型列表
export const getMaterialCateList = function ({data}){
    return req({url:'/manage/qms.material/getMaterialCateList',data})
}
//产品类型所属分类
export const getCateSelct = function ({data}){
    return req({url:'/manage/qms.material/getCateSelct',data})
}
//新增与编辑产品类型
export const saveMaterialCate = function (data){
    return req({url:'/manage/qms.material/saveMaterialCate',data})
}
//删除产品类型
export const delMaterialCate = function (data){
    return req({url:'/manage/qms.material/delMaterialCate',data})
}
//产品列表
export const getMaterialList = function ({_this,data}){
    return method.get_list({_this,url:'/manage/qms.material/getList',data})
}
//产品列表（无分页）
export const getMaterialListNoPage = function (data){
    return req({url:'/manage/qms.material/getList',data})
}
//新增与编辑产品
export const saveMaterial = function (data){
    return req({url:'/manage/qms.material/saveMaterial',data})
}
//删除产品
export const delMaterial = function (data){
    return req({url:'/manage/qms.material/delMaterial',data})
}
//编辑产品状态
export const changeMaterialStatus = function (data){
    return req({url:'/manage/qms.material/changeMaterialStatus',data})
}


//工厂列表
export const getFactoryList = function ({_this,data}){
    return method.get_list({_this,url:'/manage/qms.factory/getFactoryList',data})
}
//新增与编辑工厂
export const saveFactoryInfo = function (data){
    return req({url:'/manage/qms.factory/saveFactoryInfo',data})
}
//删除工厂
export const delFactoryInfo = function (data){
    return req({url:'/manage/qms.factory/delFactoryInfo',data})
}
//编辑工厂状态
export const changeFactoryStatus = function (data){
    return req({url:'/manage/qms.factory/changeFactoryStatus',data})
}


//试验类型列表
export const getExperimentTypeList = function ({_this,data}){
    return method.get_list({_this,url:'/manage/qms.experiment_type/getExperimentTypeList',data})
}
//新增与编辑试验类型
export const saveExperimentDetail = function (data){
    return req({url:'/manage/qms.experiment_type/saveExperimentDetail',data})
}
//删除试验类型
export const delExperimentTypeInfo = function (data){
    return req({url:'/manage/qms.experiment_type/delExperimentTypeInfo',data})
}
//编辑试验类型状态
export const changeExperimentType = function (data){
    return req({url:'/manage/qms.experiment_type/changeExperimentType',data})
}


//供应商列表
export const getSupplierList = function ({_this,data}){
    return method.get_list({_this,url:'/manage/qms.supplier/getSupplierList',data})
}
//供应商详情
export const getSupplierDetail = function ({_this,data}){
    return req({url:'/manage/qms.supplier/getSupplierDetail',data})
}
//新增与编辑供应商
export const saveSupplierInfo = function (data){
    return req({url:'/manage/qms.supplier/saveSupplierInfo',data})
}
//删除供应商
export const delSupplierInfo = function (data){
    return req({url:'/manage/qms.supplier/delSupplierInfo',data})
}
//编辑供应商状态
export const changeSupplierStatus = function (data){
    return req({url:'/manage/qms.supplier/changeSupplierStatus',data})
}
//新增与编辑供应商联系人
export const saveContactInfo = function (data){
    return req({url:'/manage/qms.supplier/saveContactInfo',data})
}
//删除供应商联系人
export const delContactInfo = function (data){
    return req({url:'/manage/qms.supplier/delContactInfo',data})
}